import { firefoxFixHeader } from './tabUtils.js'

const handleTriggerClick = (triggers, contents, index) => () => {
    triggers.forEach((trigger) => trigger.classList.remove('tabs__trigger--active'))
    contents.forEach((content, contentIndex) => {
        content.classList.remove('tabs__content--active')

        if (index === contentIndex) {
            const activeTab = content.closest('.swiper')
            if (activeTab) {
                const tabWidth = activeTab.offsetWidth
                content.style.width = `${tabWidth}px`
            }

            content.classList.add('tabs__content--active')
            triggers[index].classList.add('tabs__trigger--active')
        }
    })
}

export function initTabs() {
    const tabEls = document.querySelectorAll('.tabs')
    tabEls.forEach((tab) => {
        const triggers = tab.querySelectorAll('.tabs__trigger')
        const contents = tab.querySelectorAll('.tabs__content')

        triggers.forEach((trigger, index) => {
            firefoxFixHeader(trigger)
            firefoxFixHeader(contents[index])
            trigger.addEventListener('click', handleTriggerClick(triggers, contents, index))
        })
    })
}

let swiper
const MEDIA_QUERY = '(max-width: 66.875rem)'

export function destroySlider() {
    if (swiper) {
        swiper.destroy(true, true)
        resetSlideHeight()
    }
}

export function createSlider() {
    // eslint-disable-next-line no-undef
    swiper = new Swiper('.tabs .swiper', {
        loop: false,
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 1,
        initialSlide: 0,
        breakpoints: {
            560: {
                slidesPerView: 1.5,
            },
        },

        on: {
            init: function () {
                setEqualHeight(this.slides)
            },
            resize: function () {
                setEqualHeight(this.slides)
            },
        },
    })

    const mediaQuery = window.matchMedia(MEDIA_QUERY)
    mediaQuery.addEventListener('change', handleResize)

    return swiper
}

let resizeTimeout = null

export function setEqualHeight(slides) {
    // Only proceed if we have slides
    if (!slides || !slides.length) {
        return
    }

    let maxHeight = 0

    // First pass: find the maximum height
    slides.forEach((slide) => {
        const content = slide.querySelector('.possibilities__content')
        if (content) {
            // Get all child elements
            const children = Array.from(content.children)

            // Calculate total height of children
            const totalHeight = children.reduce((sum, child) => {
                return sum + child.offsetHeight
            }, 0)

            // Add gaps between elements (1rem per gap)
            const gapHeight =
                (children.length - 1) * parseFloat(getComputedStyle(content).gap || 16)

            // Add padding
            const paddingTop = parseFloat(getComputedStyle(content).paddingTop)

            // Calculate total height
            const height = totalHeight + gapHeight + paddingTop

            // Update max height
            if (height > maxHeight) {
                maxHeight = height
            }
        }
    })

    // Second pass: apply max height to all slides
    slides.forEach((slide) => {
        slide.style.minHeight = `${maxHeight}px`
    })

    // get height of the tallest slide
    const tallestSlide = Math.max(...slides.map((slide) => slide.offsetHeight))

    // Third pass: apply max height to all slides
    slides.forEach((slide) => {
        slide.style.minHeight = `${tallestSlide}px`
    })
}

// Debounced resize handler
function handleResize() {
    if (resizeTimeout) {
        clearTimeout(resizeTimeout)
    }
    resizeTimeout = setTimeout(() => {
        if (swiper) {
            setEqualHeight(swiper.slides)
        }
    }, 100)
}

export function resetSlideHeight() {
    document.querySelectorAll('.swiper-slide').forEach((slide) => {
        slide.style.minHeight = ''
    })
}

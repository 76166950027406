document.addEventListener('DOMContentLoaded', () => {
    function attachYandexEvent(selector, eventType, yandexGoal) {
        var elements = document.querySelectorAll(selector)

        elements.forEach(function(element) {
            element.addEventListener(eventType, function() {
                try {
                    // eslint-disable-next-line
                    ym(95519912, 'reachGoal', yandexGoal)
                    // eslint-disable-next-line
                } catch (error) {}
            })
        })
    }

    function attachYandexDynamicEvent(parentSelector, childSelector, eventType, yandexGoal) {
        var parentElements = document.querySelectorAll(parentSelector)

        parentElements.forEach(function(parentElement) {
            var childElements = parentElement.querySelectorAll(childSelector)

            childElements.forEach(function(childElement) {
                // eslint-disable-next-line
                childElement.addEventListener(eventType, function() {
                    try {
                        // eslint-disable-next-line
                        ym(95519912, 'reachGoal', yandexGoal)
                        // eslint-disable-next-line
                    } catch (error) {}
                })
            })
        })
    }

    // eslint-disable-next-line
    function attachFormEvent(parentSelector, formSelector) {
        var parentElements = document.querySelectorAll(parentSelector)

        parentElements.forEach(function(parentElement) {
            var formElements = parentElement.querySelectorAll(formSelector)

            formElements.forEach(function(formElement) {
                // eslint-disable-next-line
                formElement.addEventListener('done', function() {
                    var yandexGoal
                    if (parentElement.classList.contains('modal--dark')) {
                        yandexGoal = 'order_franshiza'
                    } else {
                        yandexGoal = 'order_platform'
                    }

                    try {
                        // eslint-disable-next-line
                        ym(95519912, 'reachGoal', yandexGoal)
                        // eslint-disable-next-line
                    } catch (error) {}
                })
            })
        })
    }

    attachYandexEvent('.hero-button', 'click', 'click_demo')

    attachYandexDynamicEvent('.tabs', '.button__inverted', 'click', 'click_demo')

    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(1) input', 'blur', 'click_name')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(2) input', 'blur', 'click_dolzhnost')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(3) input', 'blur', 'click_company')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(4) input', 'blur', 'click_phone')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(5) input', 'blur', 'click_email')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(6) input', 'blur', 'click_otrasl')
    // eslint-disable-next-line
    attachYandexDynamicEvent('.modal__modal.order__modal', '.order__inputs .order__input-wrapper:nth-child(7) input', 'change', 'click_file')

    attachYandexDynamicEvent('.tariff', '.switch-theme div:nth-child(3)', 'click', 'click_half')
    attachYandexDynamicEvent('.tariff', '.switch-theme div:nth-child(2)', 'click', 'click_month')

    // attachFormEvent('#order', '.modal .order-form')

    attachYandexEvent('.block-icons .icon:nth-child(2) a', 'click', 'click_linkedin')

    attachYandexDynamicEvent('.section-tariff', '.button', 'click', 'click_vybrat')
    attachYandexEvent('#open-modal-platform', 'click', 'click_platform')
    attachYandexEvent('#open-modal-franchize', 'click', 'click_franshiza')
    attachYandexEvent('.style-link', 'click', 'click_mail_footer')
    attachYandexEvent('.button__accent', 'click', 'click_partner')
    attachYandexEvent('.start-way-in-it__button', 'click', 'click_nachat')
    attachYandexDynamicEvent('.tabs-block__content', '.button.button__primary', 'submit', 'form_otpravka')

})

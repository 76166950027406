import { initTabs } from './tabs/tabHandlers.js'
import { createSlider, destroySlider } from './tabs/tabSlider.js'

function handleMediaQuery(mediaQuery) {
    if (mediaQuery.matches) {
        createSlider()
    } else {
        initTabs()
        destroySlider()
    }
}

export default function tabs() {
    const mediaQuery = window.matchMedia('(max-width: 66.875rem)')
    handleMediaQuery(mediaQuery)
    mediaQuery.addEventListener('change', () => handleMediaQuery(mediaQuery))
}
